<template>
  <div class="box-content relative overflow-hidden" :class="props.classes">
    <picture
      v-if="props.imageData"
      class="relative"
      style="
        object-fit: inherit;
        max-height: inherit;
        max-width: inherit;
        height: inherit;
      "
    >
      <source :srcset="imageData.mobile.url" media="(max-width: 768px)" />
      <Image
        ref="imageRef"
        style="
          object-fit: inherit;
          max-height: inherit;
          max-width: inherit;
          height: inherit;
        "
        :style="focalPoint"
        class="w-full h-full"
        loading="lazy"
        :src="image.url"
        :alt="image.altText"
        :title="image.title"
      />
    </picture>
  </div>
</template>
<script setup lang="ts">
import { mq_breakpointIsMobile } from '@/injectionSymbols';
import type { BasicImageData } from './type';
import { Image } from '@/complib';

// WIP: hybrid image works both with handler and raw data, TODO: remaove image handler

const isMobile = inject(mq_breakpointIsMobile);

const props = defineProps({
  imageData: {
    type: Object as PropType<BasicImageData>,
    required: true,
    default: {} as BasicImageData,
  },
  classes: {
    type: String,
    required: false,
    default: '',
  },
});

const image = computed(() => {
  return props.imageData[isMobile.value ? 'mobile' : 'desktop'];
});

const focalPoint = computed(() => {
  const focalPoint = image.value.focalPoint;
  if (focalPoint && focalPoint.length) {
    return `object-position: ${focalPoint[0] * 100}% ${focalPoint[1] * 100}%`;
  }
  return `object-position: ${50}% ${50}%`;
});
</script>
